<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Row type="flex">
        <Col v-bind="grid">
          <Button type="primary" icon="md-add" @click="handleCreate">添加语言</Button>
        </Col>
      </Row>
      <Table
        :columns="columns"
        :data="langList"
        ref="table"
        class="l-m-t25"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="icons">
          <div class="tabBox_img" v-viewer>
            <img v-lazy="row.icon" />
          </div>
        </template>
        <template slot-scope="{ row }" slot="language_name">
          <div class="acea-row row-middle row-center">
            <span>{{ row.language_name }}</span>
            <Tag class="ml10" color="default" v-if="row.is_default">默认</Tag>
          </div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <i-switch
            v-model="row.status"
            :value="row.status"
            :true-value="1"
            :false-value="0"
            @on-change="handleChangeSwitch(row)"
            size="large">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <a @click="handleEdit(row, '编辑语言', index)">编辑</a>
          <Divider type="vertical" />
          <a class="l-color-error" @click="handleDelete(row, '删除语言', index)">删除</a>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit" />
      </div>
    </Card>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getLangTypeListApi,
  getLangTypeFormApi,
  setLangTypeStatusApi
} from '@/api/system'

export default {
  name: 'type',
  mixins: [mixins],
  data () {
    return {
      // 搜索条件
      searchWhere: {
        page: 1,
        limit: 15
      },
      // 列表表格数据
      langList: [],
      columns: [
        {
          title: 'ID',
          key: 'id',
          width: 200,
          align: 'center'
        },
        {
          title: '语言名称',
          slot: 'language_name',
          minWidth: 200,
          align: 'center'
        },
        {
          title: '浏览器语言识别码',
          key: 'file_name',
          minWidth: 200,
          align: 'center'
        },
        {
          title: '状态',
          slot: 'status',
          width: 100,
          filters: [
            {
              label: '开启',
              value: 1
            },
            {
              label: '关闭',
              value: 0
            }
          ],
          filterMethod (value, row) {
            return row.status === value
          },
          filterMultiple: false,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 120,
          align: 'center'
        }
      ]
    }
  },
  mounted () {
    this.getLangListRequest()
  },
  methods: {
    // 获取语言列表
    getLangListRequest () {
      getLangTypeListApi(this.searchWhere).then(res => {
        let data = res.data
        this.langList = data.list
        this.total = data.count
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 创建语言分类
    handleCreate () {
      this.$modalForm(getLangTypeFormApi(0)).then(() => this.getLangListRequest())
    },
    // 编辑语言分类
    handleEdit (row) {
      this.$modalForm(getLangTypeFormApi(row.id)).then(() => this.getLangListRequest())
    },
    // 控制显示或隐藏
    handleChangeSwitch (row) {
      setLangTypeStatusApi(row.id, row.status).then((res) => {
        this.$Message.success(res.msg)
      }).catch((res) => {
        row.status = !row.status ? 1 : 0
        this.$Message.error(res.msg)
      })
    },
    // 语言分类的删除
    handleDelete (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: `system/lang/type/${row.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$modalSure(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.list.splice(num, 1)
        this.getLangListRequest()
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理分页搜索
    handlePageChange (index) {
      this.searchWhere.page = index
      this.getLangListRequest()
    }
  }
}
</script>

<style scoped>

</style>
